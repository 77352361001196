import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous - Découvrez Sovixel Rentals
			</title>
			<meta name={"description"} content={"Chaque voyage a une histoire, commencez la vôtre ici"} />
			<meta property={"og:title"} content={"A propos de nous - Découvrez Sovixel Rentals"} />
			<meta property={"og:description"} content={"Chaque voyage a une histoire, commencez la vôtre ici"} />
			<meta property={"og:image"} content={"https://sovixel.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://sovixel.com/img/2107174.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://sovixel.com/img/2107174.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://sovixel.com/img/2107174.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://sovixel.com/img/2107174.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://sovixel.com/img/2107174.png"} />
			<meta name={"msapplication-TileImage"} content={"https://sovixel.com/img/2107174.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://sovixel.com/img/5.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Notre histoire
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Sovixel Rentals est né d'un garage unique et d'une passion pour la route, avec la conviction que chaque voyage doit être extraordinaire. Nous sommes passés d'une petite collection de vélos à un service de location de premier plan, mais notre mission principale reste la même : fournir aux aventuriers les moyens d'explorer librement, en toute sécurité et de manière mémorable. Notre histoire s'écrit sur les routes empruntées par nos clients, dans les aventures qu'ils ont vécues et les souvenirs qu'ils ont créés.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Expérience personnalisée: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Chaque cycliste est unique, et nous vous proposons des recommandations personnalisées en fonction de votre style et de votre niveau d'aventure.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Engagement en matière de développement durable: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Nous nous engageons à réduire notre empreinte écologique en entretenant des vélos économes en carburant et en adoptant des pratiques écologiques.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							La sécurité avant tout: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Équipés d'un matériel de sécurité haut de gamme et d'une assistance complète aux pilotes, nous veillons à ce que votre aventure soit à la fois passionnante et sûre.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					overflow-x="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						position="absolute"
						display="block"
						width="100%"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://sovixel.com/img/6.jpg"
						object-fit="cover"
						top="auto"
						right={0}
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://sovixel.com/img/7.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 8px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--headline2"
						md-text-align="left"
					>
						Embarquez pour votre aventure
					</Text>
				</Box>
				<Box
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						Avec Sovixel, votre voyage n'est pas seulement une question de destination, c'est aussi une question de découverte. Que vous recherchiez la solitude sur les routes ouvertes ou la camaraderie des autres conducteurs, nous vous offrons la liberté de créer votre propre histoire. Embrassez l'esprit d'aventure, le frisson de la conduite et la joie des nouvelles découvertes.
					</Text>
				</Box>
				<Box
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 16px"
					display="flex"
					lg-align-items="center"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					lg-padding="0px 16px 16px 16px"
				>
					<Text
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						md-text-align="left"
					>
						Votre prochaine grande aventure est à portée de main. Commencez votre voyage avec Sovixel Rentals - là où naissent les histoires sur la route.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});